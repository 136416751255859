import {GET_USER_DATA,LOG_OUT,POST_USER_DATA} from '../Type';

const INITIAL_STATE = {
  userData: {},
};

const userReducer = (state = INITIAL_STATE, action:any) => {
    switch (action.type) {
        case POST_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            };
        case LOG_OUT:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
}

export { userReducer}