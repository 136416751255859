import React from 'react';
import { Link } from 'react-router-dom';
import Chip from '../../Chip/index';
import './styles.css';

const BlogItem = ({
  blog: {
    description,
    title,
    createdAt,
    authorName,
    authorAvatar,
    cover,
    category,
    id,
  },
}) => {

  const convert = (createdAt)=>{
    const date = new Date(0)
    date.setUTCSeconds(createdAt / 1000)
    return date;
  }

  return (
    <div className='blogItem-wrap'>
      <img className='blogItem-cover' src={cover} alt='cover' />
      <Chip label={category} />
      <Link to={`/blog/${id}`}><h3>{title}</h3></Link>
      <p className='blogItem-desc'>{description}</p>
      <footer>
        <div className='blogItem-author'>
          <img src={authorAvatar} alt='avatar' />
          <div>
            <h6>{authorName}</h6>
            <p>{convert(createdAt).toDateString()}</p>
          </div>
        </div>
        <Link className='blogItem-link' to={`/blog/${id}`}>
          ➝
        </Link>
      </footer>
    </div>
  );
};

export default BlogItem;
