import React, { useState } from 'react';
import EmptyList from '../../components/EmptyList';
import BlogList from '../../components/BlogList';
import {
  Stack,
} from "@chakra-ui/react";
import SearchBar from '../../components/SearchBar';
import {preFetchMethod,getAllBlogs } from "../queries";

const BlogHome = () => {
  const [blogList,setblogList] = useState([]);
  const [blogs, setBlogs] = useState(blogList);
  const [searchKey, setSearchKey] = useState('');

  // Search submit
  const handleSearchBar = (e) => {
    e.preventDefault();
    handleSearchResults();
  };

  // Search for blog by category
  const handleSearchResults = () => {
    const allBlogs = blogList;
    const filteredBlogs = allBlogs.filter((blog) =>
      blog.category.toLowerCase().includes(searchKey.toLowerCase().trim())
    );
    setBlogs(filteredBlogs);
  };

  const getAllList = ()=>{
    preFetchMethod(getAllBlogs,{"name":"Deepanshu"}).then(res => res.json())
    .then(res => {
      console.log(res);
      setblogList(res.data.blogList);
      setBlogs(res.data.blogList);
      
      // showSuccessToast("Blog Published");
      // navigate('/signin');
    }).catch((err)=>{
      console.log(err);
      // showErrorToast('Something went wrong, Please try again later');
    })
  };

  // Clear search and show all blogs
  const handleClearSearch = () => {
    setBlogs(blogList);
    setSearchKey('');
  };

  React.useEffect(() => {
    console.log(blogs);
    getAllList();
  },[])

  return (
    <Stack mx={10}>

      {/* Search Bar */}
      <SearchBar
        value={searchKey}
        clearSearch={handleClearSearch}
        formSubmit={handleSearchBar}
        handleSearchKey={(e) => setSearchKey(e.target.value)}
      />

      {/* Blog List & Empty View */}
      {(!blogs || !blogs.length) ? <EmptyList /> : <BlogList blogs={blogs} />}
    </Stack>
  );
};

export default BlogHome;
