import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../../utils/general";
import { languageOptions } from "../../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './styles.css'

import { defineTheme } from "../../lib/defineTheme";
import useKeyPress from "../../hooks/useKeyPress";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import { addCode, getCode, preFetchMethod } from "../../Pages/queries";
import { useParams } from "react-router";
import { useColorMode } from "@chakra-ui/react";
import copy from "copy-to-clipboard";

const javascriptDefault = `/* Problem: Merge Sort.*/

// Time: O(log n)
function mergeSortedArrays(leftArray, rightArray, arr) {
  try {
    let n1 = leftArray.length;
    let n2 = rightArray.length;
    let counter1 = 0, counter2 = 0, j =0;
    while(counter1 < n1 &&  counter2 < n2) {
      if (leftArray[counter1] > rightArray[counter2]) {
        arr[j++] = rightArray[counter2++];
      } else if (leftArray[counter1] < rightArray[counter2]) {
          arr[j++] =  leftArray[counter1++];
      } else {
         arr[j++] = rightArray[counter2++];
         arr[j++] =  leftArray[counter1++];
      }
    }
   while(counter1 < n1) {
     arr[j++] =  leftArray[counter1++];
   }
   while(counter2 < n2) {
     arr[j++] = rightArray[counter2++];
   }
} catch (e) {
    throw e;
}
}
function MergeSort(arr) {
  try {
    let result = [];
    let n = arr.length;
    if (n < 2) {
      return arr;
    }
  let mid = parseInt(n/2);
  let leftArray = [];
  let rightArray = [];
  for (let i = 0; i < mid; i++) {
    leftArray.push(arr[i]);
  }
  for (let i = mid; i<= n-1; i++) {
    rightArray.push(arr[i]);
  }
  MergeSort(leftArray);
  MergeSort(rightArray);
  mergeSortedArrays(leftArray, rightArray, arr)
} catch (e) {
    throw e;
}
}
const A = [9,3,2,1,0];
MergeSort(A)
console.log(A);

//Happy Coding By Deepanshu`;

const Landing = () => {
  const [code, setCode] = useState(javascriptDefault);
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions[0]);
  const [codeId,setCodeId] =useState(null);
  const { id } = useParams();
  const { colorMode, toggleColorMode } = useColorMode();
  let classes = colorMode === 'dark' ? "font-semibold px-2 py-1 rounded-md bg-gray-100 text-black cursor-pointer": "font-semibold px-2 py-1  my-5 rounded-md bg-gray-100 cursor-pointer"

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setLanguage(sl);
  };

  const copyToClipboard = () => {
    copy(codeId);
    showSuccessToast(`You have copied "${codeId}"`);
 }

  const shareCode = () => {
    console.log(code,customInput,language);
    var variables = {
      code: code,
      languageId: language.id,
      codeId: codeId,
    };
    preFetchMethod(addCode,variables).then(res => res.json())
    .then(res => {
      console.log(res);
      if(res?.data?.addCode){
        setCodeId(res?.data?.addCode);
        showSuccessToast("Code Shared Successfully with " + res?.data?.addCode);
      }
      return res;
    }).catch(e => {
      console.log("===Check error ===",e);
      return e;
    });
  };

  useEffect(() => {
    if(id && id !== undefined){
      setCodeId(id);
      var variables = {
        codeId: id  
      };
      preFetchMethod(getCode,variables).then(res => res.json())
      .then(res => {
        if(res?.data?.getCode){
          setCode(res?.data?.getCode?.code);
          var lang = languageOptions.find((v)=>v.id === res?.data?.getCode?.languageId);
          onSelectChange(lang !== undefined ? lang : languageOptions[0]);
        }else{
          showErrorToast("Not a Valid CodeId",3000);
        }
      }).catch(e => {
        console.log("===Check error ===",e);
        return e;
      });
    }
  },[id]);

  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
      handleCompile();
    }
  }, [ctrlPress, enterPress]);
  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };
  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      language_id: language.id,
      // encode source code in base64
      source_code: btoa(code),
      stdin: btoa(customInput),
    };
    const options = {
      method: "POST",
      url: 'https://judge0-ce.p.rapidapi.com/submissions',
      params: {base64_encoded: 'true', fields: '*'},
      headers: {
      'content-type': 'application/json',
      'Content-Type': 'application/json',
      'X-RapidAPI-Key': '66fc612b59mshaecb78045f7e20dp1fe14fjsnab3259a58983',
      'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com'
        },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response);
        console.log("res.data", response.data);
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        console.log(err);
        let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        console.log("status", status);
        if (status === 429) {
          console.log("too many requests", status);

          showErrorToast(
            `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
            10000
          );
        }
        setProcessing(false);
        console.log("catch block...", error);
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: `https://judge0-ce.p.rapidapi.com/submissions/${token}`,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        'X-RapidAPI-Key': '66fc612b59mshaecb78045f7e20dp1fe14fjsnab3259a58983',
        'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com'
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        showSuccessToast(`Compiled Successfully!`);
        console.log("response.data", response.data);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
    }
  };

  function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="flex flex-row row px-4 smallCompilerCenter pt-3">
        <div className="py-2 col-md-2 col-sm-5">
          <LanguagesDropdown onSelectChange={onSelectChange} value={language} />
        </div>
        <div className="py-2 col-md-1 col-sm-5">
          <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
        </div>
      </div>
      <div className="flex flex-row space-x-4 items-start px-4 py-4 smallCompiler">
        <div className="flex flex-col w-full h-full justify-start items-end">
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={language?.value}
            theme={theme.value}
          />
        </div>

        <div className="flex flex-shrink-0 w-[30%] flex-col smallCompilerContainer">
          <OutputWindow outputDetails={outputDetails} />
          <div className="flex flex-col items-end">
            <CustomInput
              customInput={customInput}
              setCustomInput={setCustomInput}
            />
          </div>
          <div className="flex row">
          <div className="col-md-6 col-sm-5">
            <button
            onClick={shareCode}
            disabled={!code}
            className={classnames(
              "mt-4 border-2 text-black border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
              !code ? "opacity-50" : ""
            )}
          >
            {"Share"}
          </button>
          </div>
          <div className="col-md-6 col-sm-5">
          <button
            onClick={handleCompile}
            disabled={!code}
            className={classnames(
              "mt-4 border-2 text-black border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
              !code ? "opacity-50" : ""
            )}
          >
            {processing ? "Processing..." : "Compile and Execute"}
          </button>
          </div>
          </div>
          <div className="flex row">
           <div className="col-md-6 col-sm-5">
            {outputDetails && <OutputDetails outputDetails={outputDetails} />}
            </div>
            <div className="col-md-6 col-sm-5">
              {codeId ?<p className={classes} onClick={copyToClipboard}>{codeId}</p> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Landing;
