import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  FormLabel,
  Link
} from "@chakra-ui/react";
import { PinInput, PinInputField } from '@chakra-ui/react'
import { ToastContainer} from "react-toastify";
import {preFetchMethod, forgetPassword,resetPassword} from "./queries";
import {showErrorToast,showSuccessToast} from '../utils/toast'
import { useNavigate } from "react-router-dom";
import { useState } from "react";


export default function ForgotPasswordForm() {
  const [email, setemail] = useState("");
  const [otp,setOtp] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [password, setpassword] = useState("");
  let navigate = useNavigate();
  const callOTP = () =>{
    if(!email){
      showErrorToast("Please enter Email Address");
      return;
    }
    const variables = {
      "email": email,
    }
    preFetchMethod(forgetPassword,variables).then(res => res.json())
    .then(res => {
      console.log(res);
      showSuccessToast("OTP SENT SUCCUSSFULY");
      localStorage.setItem("resetToken",res.data.forgetPassword.token)
      setIsValid(true)
      // navigate('/signin');
    }).catch((err)=>{
      console.log(err);
      showErrorToast('Something went wrong, Please try again later');
    })
  
  }

  const callResetPass = () => {
    if(!email){
      showErrorToast("Please enter Email Address");
      return;
    }
    if(!otp){
      showErrorToast("Please enter OTP");
      return;
    }
    if(!password){
      showErrorToast("Please enter Password");
      return;
    }

    const variables = {
      "token": localStorage.getItem("resetToken"),
      "otp": otp,
      "password": password
    };

    preFetchMethod(resetPassword,variables).then(res => res.json())
    .then(res => {
      console.log(res);
      showSuccessToast("Successfuly Reset Password");
      navigate('/signin');
      // setIsValid(true);
    }).catch((err)=>{
      console.log(err);
      showErrorToast('Something went wrong, Please try again later');
    })
  }
  return (
    <>
    <ToastContainer
    position="top-right"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Forgot your password?
        </Heading>
        <Text
          fontSize={{ base: "sm", sm: "md" }}
          color={useColorModeValue("gray.800", "gray.400")}
        >
          You&apos;ll get an email with a reset link
        </Text>
        <FormControl id="email" isRequired>
        <FormLabel>Email</FormLabel>
          <Input
            onChange={(val)=>setemail(val.target.value)}
            placeholder="your-email@example.com"
            _placeholder={{ color: "gray.500" }}
            type="email"
          />
        </FormControl>
        {isValid && (
          <>
        <FormControl  id="otp" isRequired>
            <FormLabel>OTP</FormLabel>
            <PinInput onChange={(val)=>setOtp(val)} size='md'  otp placeholder="">
              <PinInputField marginLeft={14} />
              <PinInputField marginLeft={3}  />
              <PinInputField marginLeft={3}   />
              <PinInputField marginLeft={3}  />
              <PinInputField marginLeft={3}  />
              <PinInputField marginLeft={3}  />
            </PinInput>
          </FormControl>
        <FormControl id="email" isRequired>
          <FormLabel>Password</FormLabel>
            <Input
              onChange={(val)=>setpassword(val.target.value)}
              placeholder="Enter Password"
              _placeholder={{ color: "gray.500" }}
              type="password"
            />
        </FormControl>
        <Stack spacing={6}>
          <Button
            // onClick={() =>callOTP()}
            onClick={() =>callResetPass()}
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
          >
            Request Reset
          </Button>
        </Stack>
        </>
        )}
      {!isValid&&<Stack spacing={6}>
          <Button
            onClick={() =>callOTP()}
            // onClick={() =>callResetPass()}
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
          >
            Request OTP
          </Button>
        </Stack>}
           {isValid && <Stack alignItems="center">
                <Link color={"blue.400"}>
                      <Text onClick={()=>{callOTP()}} fontSize={12}>Resend OTP</Text>
                </Link>
            </Stack>}
      </Stack>
    </Flex>
    </>
  );
}
