import {LOGIN_STATUS,LOG_OUT} from '../Type';

const INITIAL_STATE = {
  loginData: {},
  isLogin: false,
};

const loginReducer = (state = INITIAL_STATE, action:any) => {
    switch (action.type) {
        case LOGIN_STATUS:
            return {
                ...state,
                loginData: action.data,
                isLogin: true
            };        
        case "persist/REHYDRATE":
            const loginDetail = action.payload ? action.payload.loginStatus.loginData : {};
            const isLogin = action.payload ? action.payload.loginStatus.isLogin : false;
            return {
                ...state,
                loginData: loginDetail,
                isLogin: isLogin
            };
            case LOG_OUT:
            return {
                ...INITIAL_STATE,

            };
        default:
            return state;
    }
}

export { loginReducer}