import URLDATA from '../../config'
import { Avatar, Box, Button, Checkbox, Flex, HStack,  Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text } from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { useEffect } from "react";
import { useState } from "react";
import { editUser, preFetchMethod } from '../../Pages/queries';
import { showErrorToast, showSuccessToast } from '../../utils/toast';
import { getUserdetailss } from '../../Redux/Action/auth';
import { useDispatch } from 'react-redux';
import { LOGIN_STATUS } from '../../Redux/Type';
import profilecss from './profile.module.css';

export const ProfileEdit = ({onClose,isOpen,loginData,userData}) => {
   const dispatch = useDispatch();
   const [profile,setProfile] = useState('https://avatars.dicebear.com/api/male/username.svg');
   const [user,setUser] = useState({
    firstName: userData && userData!==undefined ? userData.firstName : '',
    lastName: userData && userData!==undefined ? userData.lastName : '',
    gender: userData?.gender,
    email: userData && userData!==undefined ? userData.email : '',
    address: userData && userData!==undefined ? userData.currentAddress : '',
    path: userData && userData!==undefined ? userData.path: 'https://avatars.dicebear.com/api/male/username.svg',
   });

   useEffect(() => {
    setUser({
      firstName: userData && userData!==undefined ? userData.firstName : '',
      lastName: userData && userData!==undefined ? userData.lastName : '',
      gender: userData?.gender,
      email: userData && userData!==undefined ? userData.email : '',
      address: userData && userData!==undefined ? userData.currentAddress : '',
      path: userData && userData!==undefined ? userData.path: 'https://avatars.dicebear.com/api/male/username.svg',
    });
    setProfile('https://avatars.dicebear.com/api/male/username.svg');
   }, [isOpen])

   function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

   const onSubmit = async()=>{
    if(!isValidEmail(user.email)){
      showErrorToast('Please Enter a valid email address');
      console.log('nopeeeeeeeeeeeeeeeeeeee');
      return;
    }
    let imageUrl;
    let uploadImage = async()=>{
        const body = new FormData();
       await body.append("files", profile[0]);
        await fetch(`${URLDATA.upload}uploadImage`, {
            method: "post",
            body: body,
          })
            .then((res) => res.json())
            .then((res) => {
              imageUrl =  `${URLDATA.upload}images/${res.filename}`;
              console.log(imageUrl);
              return;
            })
            .catch((err) => {
                console.log(err);
                return null;
            });
    }
    if(profile && Array.isArray(profile) && profile.length){
      await uploadImage();
      await console.log(imageUrl,profile[0]);
    }
      let variables = {
        "id": loginData?.id,
        "first": user.firstName,
        "last": user.lastName,
        "name": user.firstName + ' ' + user.lastName,
        "about": "Hey All",
        "contact": "88282222",
        "email": user.email,
        "path": imageUrl ? imageUrl : userData.path,
        "curradd": user.address,
        "gender": user.gender
      }
        preFetchMethod(editUser,variables).then(res => res.json())
      .then(res => {
        if(res?.errors){
          showErrorToast(res?.errors.length ? res?.errors[0]?.message : 'Something went wrong, Please try again later');
          return;
        }
        console.log(res);
        onClose();
        dispatch(getUserdetailss());
        dispatch({type: LOGIN_STATUS, data: {
          "id": loginData?.id,
          "name": variables.name,
          "token": loginData?.token,
          "path": variables.path
      }}); 
        showSuccessToast("Profile Updated");
      }).catch((err)=>{
        console.log(err);
        showErrorToast('Something went wrong, Please try again later');
      })
   };

    return (
        <Modal onClose={onClose} size='xl' isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User Profile Edit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
                <div style={{display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                  <Dropzone onDrop={acceptedFiles => {
                    console.log(acceptedFiles);
                    setProfile(acceptedFiles); 
                    setUser({...user,path:acceptedFiles[0].preview})
                    }} className={profilecss.dropzone}>
                  {dropzoneProps => {
                        return (
                          <>
                          <Avatar
                            src={
                              user.path
                                ? user.path
                                : 'https://avatars.dicebear.com/api/male/username.svg'
                            }
                            onError={(e) => { e.target.src = 'https://avatars.dicebear.com/api/male/username.svg' }}
                            size={'2xl'}
                          />
                          <div className={profilecss.overlay}>
                          <CameraAltOutlinedIcon fontSize="8"/>
                          </div>
                        </>
                     );
                    }}
               </Dropzone>
                </div>
                <div class="col-12">
                <HStack spacing='34px' display='flex' justifyContent='space-between'>
                  <Box p='2' spacing={5}>
                      <p className='uppercase'>First Name</p>
                      <Box bg='#D3D3D3' minW={'20'}  maxW={'40'} minH="8">
                        <Input color='currentcolor' value={user.firstName} size='sm' onChange={(e)=>{setUser({...user,firstName: e.target.value})}}  />
                      </Box>
                  </Box>
                  <Spacer />
                  <Box p='2' float='right'>
                    <Text className='uppercase'>Last Name</Text>
                    <Box bg='#D3D3D3' minW={'20'} maxW={'40'} minH="8">
                    <Input value={user.lastName} size='sm' onChange={(e)=>{setUser({...user,lastName: e.target.value})}} />
                    </Box>
                  </Box>
                </HStack>
                <Box p='2' spacing={5}>
                    <p className='uppercase'>Gender</p>
                    <Flex gap={5}>
                      <Checkbox onChange={(e)=>{setUser({...user,gender: 'Male'})}} isChecked={user && user!==undefined ? user.gender === "Male" : false} size='md' colorScheme='green' 
                      >
                        MALE
                      </Checkbox>
                      <Checkbox onChange={(e)=>{setUser({...user,gender: 'Female'})}} isChecked={user && user!==undefined ? user.gender !== "Male" : false}  
                      size='md' colorScheme='green'>
                        FEMALE
                      </Checkbox>
                    </Flex>
                </Box>
                <Spacer />
                <Box p='2'>
        
                </Box>
                <Box p='2' spacing={5}>
                    <p className='uppercase'>Email</p>
                    <Box bg='#D3D3D3'  minW={'40'} h="8">
                    <Input value={user.email} size='sm' onChange={(e)=>{setUser({...user,email: e.target.value})}} />
                    </Box>
                </Box>
                <Spacer />
                <Box p='2'>
        
                </Box>
                <Box p='2'>
                  <p className='uppercase'>Address</p>
                  <Box bg='#D3D3D3' className='verticalAlign' minW={'40'} h="8">
                  <Input type={'email'} color='currentcolor' onChange={(e)=>{setUser({...user,address: e.target.value})}} value={user.address} size='sm' />
                  </Box>
                </Box>
                <Spacer />
                <Box p='2'>
        
                </Box>
                </div>
            </div>

          </ModalBody>
          <ModalFooter>
          <Button onClick={()=>onSubmit()} colorScheme='blue' mr={3}>
              Done
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal> 
    );
}