import { Avatar, Box, Button, Checkbox, Flex, Grid, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text } from "@chakra-ui/react";
import profilecss from './profile.module.css';

export const Profile = ({onClose,isOpen,loginData,userData,editOpen}) => {

    return (
        <Modal onClose={onClose} size='xl' isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
                <div style={{display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <Avatar
                        size={'2xl'}
                        src={userData?.path?userData?.path:'https://avatars.dicebear.com/api/male/username.svg'}
                      />
                </div>
                <div class="col-12">
                <HStack spacing='34px' display='flex' justifyContent='space-between'>
                  <Box p='2' spacing={5}>
                      <p className='uppercase'>First Name</p>
                      <Box bg='#D3D3D3' minW={'20'}  maxW={'40'} minH="8">
                      <Text pl={2} pr={2} pt='1'>{userData && userData!==undefined ? userData.firstName : ''}</Text>
                      </Box>
                  </Box>
                  <Spacer />
                  <Box p='2' float='right'>
                    <Text className='uppercase'>Last Name</Text>
                    <Box bg='#D3D3D3' minW={'20'} maxW={'40'} minH="8">
                    {/* <Input color='currentcolor' disabled={true} value='Yadav' size='sm' /> */}
                        <Text  pl={2} pr={2} pt='1'>{userData && userData!==undefined ? userData.lastName : ''}</Text>
                    </Box>
                  </Box>
                </HStack>
                <Box p='2' spacing={5}>
                    <p className='uppercase'>Gender</p>
                    <Flex gap={5}>
                      <Checkbox disabled={true} isChecked={userData && userData!==undefined ? userData.gender === "Male" : false} size='md' colorScheme='green' 
                    //   onChange={()=>{setMale(true)}}
                      >
                        MALE
                      </Checkbox>
                      <Checkbox disabled={true} isChecked={userData && userData!==undefined ? userData.gender !== "Male" : false} 
                    //   onChange={()=>{setMale(false)}}    
                      size='md' colorScheme='green'>
                        FEMALE
                      </Checkbox>
                    </Flex>
                </Box>
                <Spacer />
                <Box p='2'>
        
                </Box>
                <Box p='2' spacing={5}>
                    <p className='uppercase'>Email</p>
                    <Box bg='#D3D3D3'  minW={'40'} h="8">
                    <Text pl={2} pr={2} pt='1'>{userData && userData!==undefined ? userData.email : ''}</Text>
                    </Box>
                </Box>
                <Spacer />
                <Box p='2'>
        
                </Box>
                <Box p='2'>
                  <p className='uppercase'>Address</p>
                  <Box bg='#D3D3D3' className='verticalAlign' minW={'40'} h="8">
                  {/* <Input color='currentcolor' disabled={true} value='Yadav' size='sm' /> */}
                    <Text  pl={2} pt='1'>{userData && userData!==undefined ? userData.currentAddress : ''}</Text>
                  </Box>
                </Box>
                <Spacer />
                <Box p='2'>
        
                </Box>
                </div>
            </div>

                  {/* <Flex>
                  <Icon as={EditIcon} />
                  <Text pl={2} pb={5} size='10'>Edit</Text>
                  </Flex> */}
              {/* </div> */}
          </ModalBody>
          <ModalFooter>
          <Button onClick={()=>{
            onClose();
            editOpen();
          }} colorScheme='blue' mr={3}>
              Edit
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal> 
    );
}