import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
} from "@chakra-ui/react";
import './createblog.css';
import { DropzoneArea } from 'material-ui-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "classic-code-block";
import URLDATA from '../../config'
import {preFetchMethod,addBlogQuery,editBlogQuery } from "../queries";
import {showErrorToast,showSuccessToast} from '../../utils/toast'
import {useSelector} from 'react-redux';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CreateBlog = () => {
//   const [blogs, setBlogs] = useState("");
//   const [searchKey, setSearchKey] = useState('');
const {
    loginStatus: {loginData,isLogin},
  } = useSelector(s => s);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [content, setContent] = useState('');
  const [coverfile, setCoverfile] = useState(null);
  let navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  
  useEffect(() => {
    if(id){
      const data = location.state?.data;
      // console.log(data,"data");
      setTitle(data.title);
      setDescription(data.description)
      setSubcategory(data.subcategory)
      setCategory(data.category)
      console.log(data.content,"zz");
      setContent(data.content)
    };
  },[id])

  const publish = async() => {
      // console.log(firstName,lastName,email,password);
    //   if(!firstName || !lastName || !email || !password){
    //     showErrorToast("Please enter all Required Fields");
    //     return;
    //   }
    console.log(title,description);
    // return;
    let imageUrl;
    let uploadImage = async()=>{
        const body = new FormData();
       await body.append("files", coverfile[0]);
        await fetch(`${URLDATA.upload}uploadImage`, {
            method: "post",
            body: body,
            // mode: "no-cors"
          })
            .then((res) => res.json())
            .then((res) => {
              imageUrl =  `${URLDATA.upload}images/${res.filename}`;
              console.log(imageUrl);
              return;
            })
            .catch((err) => {
                console.log(err);
                return null;
            });
    }
    await uploadImage();
    await console.log(imageUrl,coverfile[0]);
    // return;
      let variables = {
        "title": title,
        "userId": loginData.id,
        "cover": imageUrl,
        "content": content,
        "description": description,
        "subcategory": subcategory,
        "category": category
      }
      if(id){
        variables.blogId = location.state?.data?.id
        variables.cover = variables.cover? variables.cover : location.state?.data?.cover;
        console.log(variables,"xxxxxxxxxxv");
        preFetchMethod(editBlogQuery,variables).then(res => res.json())
      .then(res => {
        console.log(res);
        showSuccessToast("Blog Updated");
        // navigate('/signin');
      }).catch((err)=>{
        console.log(err);
        showErrorToast('Something went wrong, Please try again later');
      })
      }
      else {
        preFetchMethod(addBlogQuery,variables).then(res => res.json())
      .then(res => {
        console.log(res);
        showSuccessToast("Blog Published");
        // navigate('/signin');
      }).catch((err)=>{
        console.log(err);
        showErrorToast('Something went wrong, Please try again later');
      })
    }
     
  }
  
function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            if(file.size > 2097152){
              reject("File must be less than 2MB");
            }
            else{
              console.log("ss");
            body.append("files", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${URLDATA.upload}uploadImage`, {
              method: "post",
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${URLDATA.upload}images/${res.filename}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
            }
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  let editorConfiguration = {
    extraPlugins: [uploadPlugin],
  };

//   React.useEffect(()=>{
//     console.log(isLogin);
//     if(!isLogin) navigate('/')
//   },[])

  return (
    <Stack my={8} mx={4}>
         <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <Heading className="header" as='h3' size='lg'>{id?"Edit ":"Create New "} Blog</Heading>
        <Stack>
            <Box>
                <FormControl id="title" isRequired>
                  <FormLabel>Blog Title</FormLabel>
                  <Input value={title} onChange={(val)=>{setTitle(val.target.value)}} type="text" />
                </FormControl>
              </Box>
            <Box>
                <FormControl id="description" isRequired>
                  <FormLabel>Desciption</FormLabel>
                  <Input value={description} onChange={(val)=>{setDescription(val.target.value)}} type="text" />
                </FormControl>
              </Box>
              <Box>
              <FormControl id="featured" isRequired>
                  <FormLabel>Upload Featured Image</FormLabel>
                  <DropzoneArea
                        acceptedFiles={['image/*']}
                        maxFileSize={500000}
                        onChange={(val)=>{setCoverfile(val)}}
                        // onChange={this.handleChange.bind(this)}
                        // showFileNames
                        dropzoneText="Please Upload Featured Image"
                        showAlerts={true}
                        filesLimit={1}
                    />
                </FormControl>
              </Box>

              <Box>
                    <Box>
                        <FormControl id="category" isRequired>
                            <FormLabel>Category</FormLabel>
                         <Input value={category} onChange={(val)=>{setCategory(val.target.value)}} type="text" />
                            </FormControl>
                    </Box>
                    <Box>
                <FormControl id="subcategory" isRequired>
                  <FormLabel>Sub Category</FormLabel>
                  <Input value={subcategory} onChange={(val)=>{setSubcategory(val.target.value)}} type="text" />
                </FormControl>
                    </Box>
                </Box>
                <Box>
                <FormControl id="content" isRequired>
                        <FormLabel>Content</FormLabel>
                        {console.log("ccccccccccccc",content)}
                        <CKEditor
                    editor={ ClassicEditor }
                    config={editorConfiguration}
                    data={content}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log(data)
                        setContent(data);
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                </FormControl>
                </Box>
                <Stack spacing={10} pt={2}>
                    <Button
                        onClick={() =>publish()}
                        loadingText="Publishing"
                        size="lg"
                        bg={"blue.400"}
                        color={"white"}
                        _hover={{
                        bg: "blue.500",
                        }}
                    >
                        Publish
                    </Button>
            </Stack>
        </Stack>
    </Stack>
  );
};

export default CreateBlog;
