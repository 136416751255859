import { toast } from "react-toastify";
  
export const showSuccessToast = (msg) => {
    toast.success(msg || `Signin Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
export const showErrorToast = (msg, timer) => {
    toast.error(msg || `Invalid Email or Password! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
