// import moment from 'moment';
// import { CallDeleteRestApi, CallGetRestHostApi } from '../../Services';
// import { showError } from '../../Utils/CommonFun';
// import Config from '../../Utils/Config';
// import { getCareCircleList, getPostByCareCircleId } from '../ReduxServicesUtil';
// import {CARE_CIRCLE_LIST, CARE_CIRCLE_SELECTED, GET_CARECIRCLE_POSTS, IS_SKIP,UPDATE_SELECTED_CC_POST} from '../Type';

import { getUserDetails, preFetchMethod } from "../../Pages/queries";
import { POST_USER_DATA } from "../Type";

export const getUserdetailss = () =>{
    return async (dispatch,getState) => {
        const {loginStatus: {loginData}} = getState()
        const variables = {
            userId: loginData?.id
          }
          return preFetchMethod(getUserDetails,variables).then(res => res.json())
          .then(res => {
            console.log(res,res.data.getUserDetails,"----=======");
            dispatch({type: POST_USER_DATA,payload: res.data.getUserDetails});
            return res;
          }).catch(e => {
            console.log("===Check error ===",e);
            return e;
          });
      };
};
// //TYPE 1 
// export const getCareCircle = (data) => async dispatch => {
//   // You can call an api or pass data into an argument
//   dispatch({type: CARE_CIRCLE_LIST,payload:data})
// };

// //TYPE 1
// export const getSelectedCareCircle = data => {
//   // You can call an api or pass data into an argument
//   return async dispatch => {
//     dispatch({type: CARE_CIRCLE_SELECTED, payload: data});
//     const postData = await getPostByCareCircleId(data?.careCircleId);
//     if (postData !=undefined) {
//       dispatch({type: GET_CARECIRCLE_POSTS, payload: postData});
//     }
//     else{
//       showError("Something went wrong!")
//     }
//   };
// };

// export const getIsSkip = (data) => async dispatch => {
//   // You can call an api or pass data into an argument
//   dispatch({type:IS_SKIP,payload:data})
// };
// // TYPE 2
// export const getCareCirleData = (data) => async dispatch => {
//     // You can call an api or pass data into an argument
//     return async ()=>{
//         return dispatch({type: CARE_CIRCLE_LIST,payload:data})
//     }
//   };

//   export const updatePostData = () => {
//     // You can call an api or pass data into an argument
//     return async (dispatch,getState)=>{
//       const {CareCircleReducer:{selectedCareCircle}} = getState()
//       const postData = await getPostByCareCircleId(selectedCareCircle?.careCircleId);
//       if (postData !=undefined) {
//         dispatch({type: GET_CARECIRCLE_POSTS,payload:postData})
//       }
//     }
//   };

//   export const deleteCareCircleById = () => {
//     // You can call an api or pass data into an argument
//     return async (dispatch,getState) => {
//       const {CareCircleReducer:{selectedCareCircle},loginStatus:{loginData}} = getState()
//       const deleteCareCircleUrl = `careCircle/${selectedCareCircle?.careCircleId}`;
//      return CallDeleteRestApi(deleteCareCircleUrl)
//         .then(async res => {
//           console.log("===Check delete response ===",res);
//           const careCircleList = await getCareCircleList(loginData?.userId)
//           if(careCircleList && careCircleList.length){
//             dispatch({type: CARE_CIRCLE_LIST,payload:careCircleList})
//             dispatch({type:CARE_CIRCLE_SELECTED,payload:careCircleList[0]})
//             const postData = await getPostByCareCircleId(
//               careCircleList[0]?.careCircleId,
//             );
//             if (postData != undefined) {
//               dispatch({type: GET_CARECIRCLE_POSTS, payload: postData});
//             }
//             return true
//           }else{
//             dispatch({type:CARE_CIRCLE_SELECTED,payload:{}})
//             return false
//           }
//         })
//         .catch(e => {
//           console.log("===Check error ===",e);
//         });

//     };
//   };