import { createStore, applyMiddleware } from 'redux';
import appreducer from './MainReducer';
import thunk from 'redux-thunk';
import logger from "redux-logger";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage: storage,
    timeout: null, 
    blacklist: ["userDetails"],
    whitelist:['loginStatus']
  }

const persistedReducer = persistReducer(persistConfig, appreducer);

export const store = createStore(persistedReducer, applyMiddleware(logger,thunk));
export const persistor = persistStore(store);
