import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  VisuallyHidden,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  useColorMode,
  Menu,
  Avatar,
  Center,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import {LOG_OUT} from '../../Redux/Type';
import {useDispatch,useSelector} from 'react-redux';
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { AiOutlineMenu } from "react-icons/ai";
import navStyles from "./navbar.module.css";
import { getUserdetailss } from "../../Redux/Action/auth";
import { Profile } from "./Profile";
import { ProfileEdit } from "./ProfileEdit";
import { ToastContainer } from "react-toastify";

export default function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ isEditOpen, setEditOpen ] = useState(false);
  const bg = useColorModeValue("white", "gray.800");
  const [ismale,setMale] = useState(false);
  const mobileNav = useDisclosure();
  const {
    loginStatus: {loginData,isLogin},
    userDetails: {userData}
  } = useSelector(s => s);
  const dispatch = useDispatch();



  useEffect(() => {
    if(isLogin){
      dispatch(getUserdetailss());
    }
  },[isLogin])
  return (
    <>
      <div className={navStyles.mobileNav}>
        <chakra.header
          bg={bg}
          w="full"
          px={{ base: 2, sm: 4 }}
          py={4}
          shadow="md"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mx="auto"
          >
            <Flex>
              <Link to="/">
                <chakra.a
                  href="/"
                  title="Choc Home Page"
                  display="flex"
                  alignItems="center"
                >
                  <img
                    className="logo"
                    hidden
                    src="https://images.fastcompany.net/image/upload/w_1280,f_auto,q_auto,fl_lossy/w_596,c_limit,q_auto:best,f_auto/fc/3034007-inline-i-applelogo.jpg"
                    alt=""
                  />
                  <VisuallyHidden>Choc</VisuallyHidden>
                </chakra.a>
                <chakra.h1 fontSize="xl" fontWeight="medium" ml="2">
                  Deepanshu Yadav
                </chakra.h1>
              </Link>
            </Flex>
            <HStack display="flex" alignItems="center" spacing={1}>
              <HStack
                spacing={1}
                mr={1}
                color="brand.500"
                display={{ base: "none", md: "inline-flex" }}
              >
                 <a href="/#features">
                  <Button variant="ghost">Features</Button>
                </a>
                <Link to="/ide">
                  <Button variant="ghost">Compiler</Button>
                </Link>
                <Link to="/blogs">
                  <Button variant="ghost">Blog</Button>
                </Link>
                {/* <Linkk href="http://me.deepanshucs.tech" isExternal> */}
                <a target="blank" href="http://me.deepanshucs.tech">
                  <Button variant="ghost"> Portfolio </Button>
                </a>
                {/* </Linkk> */}
                <Button onClick={toggleColorMode}>
                  {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                </Button>
              </HStack>
              {!isLogin?  <> <Link to="/signin">
                  <Button variant="ghost"> Sign in</Button>
                </Link>
              <Link to="/signup">
                <Button colorScheme="brand" size="sm">
                  Get Started
                </Button>
              </Link></>: <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    size={'sm'}
                    src={loginData.path?loginData.path:'https://avatars.dicebear.com/api/male/username.svg'}
                  />
                </MenuButton>
                <MenuList alignItems={'center'}>
                  <br />
                  <Center>
                    <Avatar
                      size={'2xl'}
                      src={loginData.path?loginData.path:'https://avatars.dicebear.com/api/male/username.svg'}
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>{loginData.name}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem onClick={() =>onOpen()}>Your Profile</MenuItem>
                  <MenuItem>Account Settings</MenuItem>
                  <MenuItem onClick={()=>{ dispatch({type: LOG_OUT}); localStorage.removeItem('token')}}>Logout</MenuItem>
                </MenuList>
              </Menu>}
              <Box display={{ base: "inline-flex", md: "none" }}>
                <IconButton
                  display={{ base: "flex", md: "none" }}
                  aria-label="Open menu"
                  fontSize="20px"
                  color={useColorModeValue("gray.800", "inherit")}
                  variant="ghost"
                  icon={<AiOutlineMenu />}
                  onClick={mobileNav.onOpen}
                />

                <VStack
                  pos="absolute"
                  top={0}
                  left={0}
                  right={0}
                  display={mobileNav.isOpen ? "flex" : "none"}
                  flexDirection="column"
                  p={2}
                  pb={4}
                  m={2}
                  bg={bg}
                  spacing={3}
                  rounded="sm"
                  shadow="sm"
                >
                  <CloseButton
                    aria-label="Close menu"
                    onClick={mobileNav.onClose}
                  />

                  <Button w="full" variant="ghost">
                    Features
                  </Button>
                  <Link to="/ide">
                  <Button  w="full" variant="ghost">Compiler</Button>
                </Link>
                  <Link  to="/blogs" w="full" variant="ghost">
                    Blog
                  </Link>
                  <Button w="full" variant="ghost">
                    Company
                  </Button>
                  <Button onClick={toggleColorMode}>
                    {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                  </Button>
                  <Button w="full" variant="ghost">
                    <Link to="/signin">Sign in</Link>
                  </Button>
                </VStack>
              </Box>
            </HStack>
          </Flex>
        </chakra.header>
      </div>
      <Profile onClose={onClose} isOpen={isOpen} loginData={loginData} userData={userData} editOpen={()=>{setEditOpen(true)}}/>
      <ProfileEdit onClose={()=>{setEditOpen(false)}} isOpen={isEditOpen} loginData={loginData} userData={userData} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Outlet />
    </>
  );
}
