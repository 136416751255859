import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as Linkk } from "react-router-dom";
import {LOGIN_STATUS} from '../Redux/Type';

import { ToastContainer, toast } from "react-toastify";
import {useDispatch,useSelector} from 'react-redux';
import React,{useEffect,useState} from "react"
import {preFetchMethod, loginQuery, signupQuery} from "./queries";
import { useNavigate } from "react-router-dom";

export default function SimpleCard() {
  const {
    loginStatus: {loginData},
  } = useSelector(s => s);
  const dispatch = useDispatch();
  const [email,setemail] = useState(null);
  const [password,setpassword] = useState(null);
  let navigate = useNavigate();

  useEffect(()=>{
    console.log(loginData);
  },[loginData]);


  const showSuccessToast = (msg) => {
    toast.success(msg || `Signin Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Invalid Email or Password! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const sumbitHandler = ()=>{
    if(!email || !password){
      showErrorToast('Please Enter Email and Password',2000)
      return;
    }
    const variables = {
      "email": email,
      "password": password
    }
    preFetchMethod(loginQuery,variables).then(res => res.json())
    .then(res => {
      if(!res.data.login) 
        return showErrorToast();
      console.log(res);
      showSuccessToast();
      localStorage.setItem('token',res.data.login.token);      
      dispatch({type: LOGIN_STATUS, data: res.data.login}); 
      setTimeout(function () {
        navigate('/');  
    }, 2000);   
    }).catch((err)=>{
      console.log(err);
      showErrorToast('Something went wrong, Please try again later');
    })

  }


  return (
    <>
    <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input onChange={(e)=>{setemail(e.target.value)}} value={email} type="email" />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input value={password} onChange={(e)=>{setpassword(e.target.value)}} type="password" />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Linkk to="/forgetpassword">
                  <Link color={"blue.400"}>Forgot password?</Link>
                </Linkk>
              </Stack>
              <Button
                onClick={()=>sumbitHandler()}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
    </>
  );
}
