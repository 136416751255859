import Navbar from "./components/Navbar/Navbar";
import HomePage from "../src/Pages/HomePage";
import SignIn from "../src/Pages/SignIn";
import Signup from "../src/Pages/Signup";
import ForgetPassword from "../src/Pages/ForgetPassword";
import Maintenace from "../src/Pages/Maintenace";
import { Routes, Route } from "react-router";
import Landing from "./components/Compiler/Landing"
import { Provider } from 'react-redux';
import { store } from './Redux/Store';

import "./App.css";
import BlogHome from "./Pages/Blog/Blog";
import BlogPage from "./Pages/Blog/BlogPage";
import CreateBlog from "./Pages/Blog/CreateBlog";

function App() {
  return (
    <Provider store={store}>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/ide" element={<Landing />} />
        <Route path="/ide/:id" element={<Landing />} />
        <Route path="/blogs" element={<BlogHome/>} />
        <Route path="/blogs" element={<BlogHome/>} />
        <Route path='/blog/:id' element={<BlogPage/>}/>
        <Route path='/createblog' element={<CreateBlog/>}/>
        <Route path='/blog/:id/editblog' element={<CreateBlog/>}/>
        <Route path="*" element={<Maintenace />} />
      </Routes>
      </Provider>
  );
}

export default App;
