import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Chip from '../../components/Chip';
import EmptyList from '../../components/EmptyList';
import ClassicEditor from "classic-code-block";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css'
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';
// import Footor from "../../components/Footor/Footor";
import './styles.css';
// import { Link } from 'react-router-dom';
// import moment from 'moment';
import {
    Heading,
    Stack,
    FormControl,
    FormLabel,
    Button
  } from "@chakra-ui/react";
import { getBlog,preFetchMethod } from '../queries';
import {useSelector} from 'react-redux';


const BlogPage = () => {
    const {
        loginStatus: {loginData,isLogin},
      } = useSelector(s => s);
    
    const [data] = useState([
        // {
        //   userId: '01a',
        //   comId: '012',
        //   fullName: 'Riya Negi',
        //   avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
        //   userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
        //   text: `<p>Hey <strong>loved</strong> your blog! Can you show me some other ways to <del><em>fix</em></del>  solve this?🤔<br>Here's my <a href="https://www.linkedin.com/in/riya-negi-8879631a9/" target="_blank">Linkedin Profile</a> to reach out.</p>`,
        //   replies: [
        //     {
        //       userId: '02a',
        //       comId: '013',
        //       userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
        //       fullName: 'Adam Scott',
        //       avatarUrl: 'https://ui-avatars.com/api/name=Adam&background=random',
        //       text: `<p>Yeah sure try adding this line to your code. You need to pass <span style="color: rgb(147,101,184);">event</span><span style="color: rgb(26,188,156);"> </span><span style="color: rgb(0,0,0);">as a param. </span></p>
        //       <pre>event.preventDefault()</pre>
        //       <p>Best of luck with your project! <br></p>
        //       <img src="https://c.tenor.com/4cR1jMpsrEgAAAAC/snoopy-cheerleader.gif" alt="undefined" style="height: auto;width: auto"/>
        //       <p></p>`
        //     },
        //     {
        //       userId: '01a',
        //       comId: '014',
        //       userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
        //       fullName: 'Riya Negi',
        //       avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
        //       text: '<p><strong>OMG!</strong> it worked! <span style="color: rgb(209,72,65);">DO NOT stop this blog series!!!!</span> 💃</p>'
        //     }
        //   ]
        // },
        // {
        //   userId: '02b',
        //   comId: '017',
        //   fullName: 'Lily',
        //   userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
        //   text: `<blockquote><strong>DRY </strong>- is the right of passage to good coding</blockquote>
        //   <p>True story brother!! <em>Amen to that!  </em>For anyone wondering DRY is&nbsp;</p>
        //   <ol>
        //   <li>Don't</li>
        //   <li>Repeat</li>
        //   <li>Yoursef</li>
        //   </ol>`,
        //   avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
        //   replies: []
        // }
      ])
  const { id } = useParams();
  const [blog, setBlog] = useState(null);


  const getBlogFun = ()=>{
    preFetchMethod(getBlog,{"id":+id}).then(res => res.json())
    .then(res => {
      console.log(res);
      setBlog(res.data.getBlog);
      createdAtDate.setUTCSeconds(blog.createdAt / 1000)
      // showSuccessToast("Blog Published");
      // navigate('/signin');
    }).catch((err)=>{
      console.log(err);
      // showErrorToast('Something went wrong, Please try again later');
    })
  };

  useEffect(() => {
    getBlogFun();
  }, []);

  const convert = (createdAt)=>{
    const date = new Date(0)
    date.setUTCSeconds(createdAt / 1000)
    return date;
  }
//   const date = 
let createdAtDate = new Date(0)

    const createMarkup = () => {
      return { __html: blog.content };
    }

  return (
    <Stack my={10}>
      {blog ? (
        <div className='blog-wrap'>
          <header>
            <p className='blog-date'>Published {convert(blog.createdAt).toDateString()}</p>
            <div style={{display: 'flex',flexDirection:'row',justifyContent: 'center'}}>
              <h1>{blog.title}</h1>
              {console.log(loginData)}
              {loginData.id === blog.userId&&<Link state={{data:blog}} to={`/blog/${id}/editblog`}><FaEdit style={{marginLeft:10,marginTop:5}} className="blog-date"/></Link>}
            </div>
            <div className='blog-subCategory'>
              {/* {blog.subCategory&&blog.subCategory.map((category, i) => ( */}
                <div>
                  <Chip label={blog.subcategory} />
                </div>
              {/* ))} */}
            </div>
          </header>
          <img src={blog.cover} alt='cover' />
          <p className='blog-desc'>
            <div dangerouslySetInnerHTML={createMarkup()} className='editor'></div>
          </p>
          <hr></hr>
          <Stack mx={2}>
            <Stack mt={6}>
            <Heading as='h5' size='md'>Article Contribued By</Heading>
            <div className='row'>
            <div className='blogItem-author col-md-6'>
                <img src={blog.authorAvatar} alt='avatar' />
                <div>
                    <h6>{blog.authorName}</h6>
                    <p>Published {convert(blog.createdAt).toDateString()}</p>
                </div>
            </div>
            <div className="col-md-6 mt-3">
                <div className="row float-right">
                    <Chip className="col-md-6" label={blog.subcategory} />
                    <Chip style={{marginLeft: "10px"}} className="col-md-6" label={blog.category} />
                </div>
            </div>
            </div>
            </Stack>
            {/* <hr></hr> */}
            <Stack style={{marginTop: '1.5rem'}}>
            {/* <Heading as='h5' size='md'></Heading> */}
            {/* <FormControl id="content" isRequired>
                        <FormLabel>Discussions</FormLabel>
                        <CKEditor
                    editor={ ClassicEditor }
                    // config={editorConfiguration}
                    data=""
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log(data)
                        // setContent(data);
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                </FormControl>
                <Button
                        // onClick={() =>publish()}
                        loadingText="Submiting"
                        size="md"
                        bg={"blue.400"}
                        color={"white"}
                        _hover={{
                        bg: "blue.500",
                        }}
                    >
                        Submit
                    </Button> */}

<CommentSection
        currentUser={{
          currentUserId: loginData.id,
          currentUserImg: loginData.path || 'https://cdn.dribbble.com/users/3293507/screenshots/14667603/media/d8cbe035a61f64afdf6deabca5182842.jpg?compress=1&resize=400x300&vertical=top',
          currentUserProfile:
            'https://www.linkedin.com/in/riya-negi-8879631a9/' || 'https://cdn.dribbble.com/users/3293507/screenshots/14667603/media/d8cbe035a61f64afdf6deabca5182842.jpg?compress=1&resize=400x300&vertical=top' ,
          currentUserFullName: loginData.name || 'Deepanshu'
        }}
        hrStyle={{ border: '0.5px solid #ff0072' }}
        commentData={data}
        currentData={(data: any) => {
          console.log('curent data', data)
        }}
        logIn={{
          loginLink: 'http://localhost:3001/',
          signupLink: 'http://localhost:3001/'
        }}
        customImg= {loginData.path || 'https://cdn.dribbble.com/users/3293507/screenshots/14667603/media/d8cbe035a61f64afdf6deabca5182842.jpg?compress=1&resize=400x300&vertical=top'}
        inputStyle={{ border: '1px solid rgb(208 208 208)' }}
        formStyle={{ backgroundColor: 'white' }}
        submitBtnStyle={{
          border: '1px solid black',
          backgroundColor: 'black',
          padding: '7px 15px'
        }}
        cancelBtnStyle={{
          border: '1px solid gray',
          backgroundColor: 'gray',
          color: 'white',
          padding: '7px 15px'
        }}
        advancedInput={true}
        replyInputStyle={{ borderBottom: '1px solid black', color: 'black' }}
      />
            
            </Stack>
          </Stack>
          {/* {blog.content} */}
        </div>
      ) : (
        <EmptyList />
      )}
         {/* <Footor /> */}
    </Stack>
  );
};

export default BlogPage;
