// LOGIN
const LOGIN_STATUS = 'LOGIN_STATUS';
const LOG_OUT = 'LOG_OUT';


const GET_USER_DATA = 'GET_USER_DATA';
const POST_USER_DATA = 'POST_USER_DATA';

export {
  LOGIN_STATUS,
  LOG_OUT,
  GET_USER_DATA,
  POST_USER_DATA
};
