import URLDATA from '../config';
export const loginQuery = `query($email:String!,$password:String!){
    login(email:$email,password:$password){
      id
      name
      token
      path
    }
  }`;

export const signupQuery = `query($firstName:String,$lastName:String,$name:String,$email:String,$password:String){
    signup(firstName:$firstName,lastName:$lastName,name:$name,email:$email,password:$password)
  }`;

export const preFetchMethod = (Query, Variables) => fetch(URLDATA.url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      query: Query,
      variables: Variables
  
    }),
    headers: {
         "Content-type": "application/json",    
    }
  
  })

export const forgetPassword = `mutation($email:String!){
  forgetPassword(email:$email){
   token
  }
}
`;

export const resetPassword = `mutation($token:String,$password:String,$otp:String){
  resetPassword(token:$token,password:$password,otp:$otp)
}
`;

export const addBlogQuery = `query($title:String,$userId:Int,$cover:String,$content:String,$description:String,$subcategory:String,$category:String){
  addBlog(title:$title,category:$category,subcategory:$subcategory,description:$description,content:$content,cover:$cover,userId:$userId)
}`;


export const getAllBlogs = `query($name:String){
  blogList(name:$name){
    id
    title
    category
    subcategory
    description
    cover
    authorName
    authorAvatar
    createdAt
    updatedAt
  }
}`;

export const getBlog = `query($id:Int){
  getBlog(id:$id){
    id
    title
    category
    subcategory
    description
    content
    cover
    userId
    createdAt
    updatedAt
    authorName
    authorAvatar
  }
}`;

export const editBlogQuery = `query($title:String,$userId:Int,$cover:String,$content:String,$description:String,$subcategory:String,$category:String,$blogId:Int){
  editBlog(title:$title,category:$category,subcategory:$subcategory,description:$description,content:$content,cover:$cover,userId:$userId,blogId:$blogId)
}`;


export const getUserDetails = `query($userId: Int){
  getUserDetails(id:$userId){
   id
  firstName
  lastName
  about
  name
  contact
  email
  path
  currentAddress
  gender
  createdAt
  updatedAt
  }
}`;

export const editUser = `query($id:Int,$first:String,$last:String,$about:String,$name:String,$contact:String,$email:String,$path:String,$curradd:String,$gender:String){
  editUser(id:$id,firstName:$first,lastName:$last,about:$about,name:$name,contact:$contact,email:$email,path:$path,currentAddress:$curradd,gender:$gender)
}`;

export const addCode = `query($code:String,$languageId:Int,$codeId:String){
  addCode(code:$code,languageId:$languageId,codeId:$codeId)
}`;

export const getCode = `query($codeId:String){
  getCode(codeId:$codeId){
    id
    code
    languageId
  }
}`;